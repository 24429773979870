import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Form.css';
import HeaderBar from '../../Components/Header/HeaderBar';
import { init, send } from 'emailjs-com';

const Form = () => {
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [content, setContent] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailConfirmError, setEmailConfirmError] = useState('');
  const [contentError, setContentError] = useState('');
  const [checkedGender, setCheckedGender] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    // e.currentTarget.reset()
    if (email === '') {
      setEmailError('メールアドレスを入力してください');
    } else {
      setEmailError('');
    }
    if (emailConfirm === '') {
      setEmailConfirmError('確認用メールアドレスを入力してください');
    } else if (email !== '' && email !== emailConfirm) {
      setEmailConfirmError(
        'メールアドレスと確認用メールアドレスが異なっています'
      );
    } else {
      setEmailConfirmError('');
    }
    if (content === '') {
      setContentError('お問い合わせ内容を入力してください');
    } else {
      setContentError('');
    }
    if (
      email !== '' &&
      emailConfirm !== '' &&
      content !== '' &&
      email === emailConfirm
    ) {
      e.preventDefault();
      init('user_37gVaV9xlx1oufSRIYwX2');
      const template_id = 'template_ocsr5rt';
      const service_id = 'service_w8h4oub';
      const send_info = {
        email: email,
        content: content,
        gender: checkedGender,
      };
      send(service_id, template_id, send_info).then(() => {
        alert('thank you!');
        history.push('/');
        console.log('送信成功');
      });
    }
  };

  const contactForm = (
    <form
      onSubmit={() => {
        handleSubmit();
      }}
    >
      <p className="item">
        メールアドレス<span>必須</span>
      </p>
      <p className="error-text">{emailError}</p>
      <input id="form-input" onChange={(e) => setEmail(e.target.value)} />
      <p className="item">
        メールアドレス(確認用)<span>必須</span>
      </p>
      <p className="error-text">{emailConfirmError}</p>
      <input
        id="form-input"
        onChange={(e) => setEmailConfirm(e.target.value)}
      />
      <p className="item">
        お問い合わせ内容<span>必須</span>
      </p>
      <p className="error-text">{contentError}</p>
      <textarea
        id="form-textarea"
        onChange={(e) => setContent(e.target.value)}
      />
      <p className="item item-gender">
        性別<span>任意</span>
      </p>
      <label key="male">
        <input
          type="radio"
          name={'male'}
          value={'male'}
          checked={checkedGender === '男' ? true : false}
          onClick={() => setCheckedGender('男')}
        />
        男性
      </label>
      <label key="female">
        <input
          type="radio"
          name={'female'}
          value={'female'}
          checked={checkedGender === '女' ? true : false}
          onClick={() => setCheckedGender('女')}
        />
        女性
      </label>
      <div className="submit-container">
        <input
          className="submit"
          type="submit"
          value="送信"
          onClick={handleSubmit}
        />
      </div>
    </form>
  );

  return (
    <div className="form-page">
      <HeaderBar />

      <div className="main" style={{ height: `calc(${window.innerHeight}px` }}>
        <Link to="/">
          <img
            className="return-button"
            src="image/戻るボタン.png"
            alt="戻るボタン"
          ></img>
        </Link>
        <div className="adjust"></div>
        <div className="form-container">{contactForm}</div>
      </div>
      <div className="d-flex copyright-container">
        <p className="text-white">powered by team SODA</p>
        <Link to="/pp" className="pp item">
          プライバシーポリシー
        </Link>
      </div>
    </div>
  );
};

export default Form;
