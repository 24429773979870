import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import DB from '../../Components/DB';
import Footer from '../../Components/Footer/Footer';
import './Custom.css';

class Custom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dn: '1',
      numberOfCustoms: 10, //Number of customs
    };
    this.gotoDetail = this.gotoDetail.bind(this);
  }

  gotoDetail(e) {
    this.setState(
      {
        dn: e.target.getAttribute('name'),
        numberOfCustoms: Object.keys(
          DB[`drink${e.target.getAttribute('name')}`]['customs']
        ).length,
      },
      () => {
        this.props.history.push({
          pathname: '/detail',
          state: {
            dn: this.state.dn,
            numberOfCustoms: this.state.numberOfCustoms,
          },
        });
      }
    );
  }

  render() {
    return (
      <div>
        <Link to="/" className="custom-header">
          <p>おすすめカスタム</p>
        </Link>
        <div
          className="background-img"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/image/背景.png)`,
          }}
        >
          <Link to="/">
            <img
              className="return-button"
              src="image/戻るボタン.png"
              alt="戻るボタン"
            ></img>
          </Link>
          <div
            className="scroll-area"
            style={{ height: `calc(${window.innerHeight}px - 85px)` }}
          >
            {(() => {
              const cards = [];
              for (let i = 1; i <= 8; i++) {
                if (i === 8) {
                  cards.push(
                    <div
                      style={{
                        width: '100%',
                        height: '120px',
                      }}
                    ></div>
                  );
                } else {
                  cards.push(
                    <div
                      className="recommendation-card"
                      onClick={(e) => this.gotoDetail(e)}
                      name={`${i}`}
                    >
                      <img
                        className="recommendation-img"
                        src={'image/drinks/' + DB[`drink${i}`]['image']}
                        name={`${i}`}
                        alt={DB[`drink${i}`]['name']}
                      ></img>
                      <p
                        className="recommendation-description-small"
                        name={`${i}`}
                      >
                        {DB[`drink${i}`]['name']}
                      </p>
                    </div>
                  );
                }
              }
              return <div>{cards}</div>;
            })()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Custom.propTypes = {
  history: PropTypes.object,
};

export default Custom;
