import React from 'react';
import { Link } from 'react-router-dom';
import './CustomDetail.scss';
import Footer from '../../Components/Footer/Footer';
import DB from '../../Components/DB';
import PropTypes from 'prop-types';

class CustomDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Customから渡ってくる
      dn: this.props.location.state.dn,
      numberOfCustoms: this.props.location.state.numberOfCustoms,
      cn: this.props.location.state.cn,
      scrollTop: this.props.location.state.scrollTop,
    };
    this.scrollRef = React.createRef();
    this.gotoResult = this.gotoResult.bind(this);
  }

  componentDidMount() {
    this.scrollRef.current.scrollTo({
      top: this.state.scrollTop,
      behavior: 'smooth',
    });
  }

  gotoResult(e) {
    this.setState(
      {
        cn: e.target.getAttribute('cn'),
        scrollTop: this.scrollRef.current.scrollTop,
      },
      () => {
        this.props.history.push({
          pathname: '/manualresult',
          state: {
            dn: this.state.dn,
            numberOfCustoms: this.state.numberOfCustoms,
            cn: this.state.cn,
            scrollTop: this.state.scrollTop,
          },
        });
      }
    );
  }

  render() {
    return (
      <div>
        <Link to="/" className="custom-header">
          <p>おすすめカスタム</p>
        </Link>
        <div
          className="background-img"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/image/背景.png)`,
          }}
        >
          <Link to="/custom">
            <div
              className="return-button"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/image/戻るボタン.png)`,
                backgroundSize: 'cover',
                zIndex: '3',
              }}
            ></div>
          </Link>
          <div
            className="scroll-area"
            style={{ height: `calc(${window.innerHeight}px)` }}
            ref={this.scrollRef}
          >
            {(() => {
              let cardsDetail = [];
              for (let i = 1; i <= this.state.numberOfCustoms; i++) {
                cardsDetail.push(
                  <div className="card">
                    <div onClick={(e) => this.gotoResult(e)} cn={i}>
                      <img
                        className="card-img"
                        src={
                          'image/drinks/' +
                          DB[`drink${this.state.dn}`]['customs'][`custom${i}`][
                            'image'
                          ]
                        }
                        cn={i}
                        alt=""
                      ></img>
                      <p className="card-name" cn={i}>
                        {
                          DB[`drink${this.state.dn}`]['customs'][`custom${i}`][
                            'name'
                          ]
                        }
                      </p>
                    </div>
                  </div>
                );
              }
              return <div className="cards-container">{cardsDetail}</div>;
            })()}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

CustomDetail.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default CustomDetail;
