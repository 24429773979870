import React, { useEffect, useState } from 'react';
import './Result/result.css';
import DB from '../Components/DB';
import PropTypes from 'prop-types';

const LookItem = ({dn, cn, setIllustMode}) => {
  return (
    <div>
        <div onClick={() => setIllustMode(false)}>
          <img
            className="return-button"
            src="image/戻るボタン.png"
            alt="戻るボタン"
          ></img>
        </div>
      <div className="item-look">
        <img
          src={`image/drinks/${
            DB[`drink${dn}`]['customs'][`custom${cn}`]['image']
          }`}
          alt={DB[`drink${dn}`]['customs'][`custom${cn}`]['name']}
        ></img>
      </div>
      <div className="item-title-container">
        <div
          className="item-title"
          style={{backgroundImage: `url(${process.env.PUBLIC_URL}/image/frameSmall.png)`}}
        >
          <p>{DB[`drink${dn}`]['customs'][`custom${cn}`]['name']}</p>
        </div>
      </div>
    </div>
  )
};

LookItem.propTypes = {
  dn: PropTypes.number,
  cn: PropTypes.number,
  setIllustMode: PropTypes.func
};


export default LookItem;