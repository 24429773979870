import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Tips from '../Tips';

const Footer = () => {
  const [id, resetId] = useState(Math.ceil(Math.random() * 19));
  useEffect(() => {
    setInterval(() => {
      resetId(Math.ceil(Math.random() * 19));
    }, 10000);
  }, []);
  return (
    <div className="footer-container">
      <div className="footer-description">
        <div className="flex-zatugaku">
          <img className="moldal-icon" src="image/moldal.png" alt=""></img>
          <h3 className="alignleft">
            スタバ雑学：<span>{Tips[`tips${id}`]['title']}</span>
          </h3>
        </div>
        <div className="des-con">
          <p className="description-bottom">{Tips[`tips${id}`]['detail']}</p>
        </div>
      </div>
      <div className="d-flex copyright-container">
        <Link to="/contact" className="form item">
          お問い合わせ
        </Link>
        <p className="item">powered by team SODA</p>
        <a className="item sutablog" href="https://sutabagacha.com/blog/">
          スタブログ
        </a>
      </div>
    </div>
  );
};

export default Footer;
