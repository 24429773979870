import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Menu.css';
import DB from '../../../Components/DB';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import PropTypes from 'prop-types';

const Menu = ({ dn, cn, isOrder, setIllustMode, setIsOrder }) => {
  const [lenofTopping, setLenofTopping] = useState(Object.keys(DB[`drink${dn}`]['customs'][`custom${cn}`]['toppings']).length);
  const sizeArray = ['Short', 'Tall', 'Grande', 'Venti']
  const [selectedSize, setSelectedSize] = useState(1)
  const [initial, setInitial] = useState('T')
  const [priceOfDrink, setPriceOfDrink] = useState(DB[`drink${dn}`]['price'][`${initial}`])
  let priceOfTopping = 0;
  for (let i = 1; i <= lenofTopping; i++){
    priceOfTopping += DB[`drink${dn}`]['customs'][`custom${cn}`]['toppings'][`topping${i}`]['price'];
  }
  const [total, setTotal] = useState(priceOfDrink + priceOfTopping);

  const sizeChange = (e) => {setSelectedSize(Number(e.target.getAttribute('value')))};

  useEffect(() => {
    setPriceOfDrink((DB[`drink${dn}`]['price'][`${sizeArray[selectedSize].substr(0, 1)}`]))
  }, [selectedSize]);

  useEffect(() => {
    setTotal(priceOfDrink + priceOfTopping)
  }, [priceOfDrink]);

  const hashtags = ['スタバガチャ'];
  const customName = DB[`drink${dn}`]['customs'][`custom${cn}`]['name'];
  const customInfo = DB[`drink${dn}`]['customs'][`custom${cn}`]['text'];
  const sweet = DB[`drink${dn}`]['customs'][`custom${cn}`]['param']['sweet'];
  const fresh = DB[`drink${dn}`]['customs'][`custom${cn}`]['param']['fresh'];
  const starEmp = '☆';
  const star = '★';

  const contents = [];
  contents.push('【' + customName + '】');
  contents.push(customInfo);
  contents.push(
    '　甘さ　　：' + star.repeat(sweet) + starEmp.repeat(5 - sweet)
  );
  contents.push(
    '　さっぱり：' + star.repeat(fresh) + starEmp.repeat(5 - fresh)
  );
  contents.push(''); //改行
  contents.push('詳しいカスタム内容はスタバガチャ！で♪');

  let linedContents = '';
  contents.forEach((e) => {
    linedContents += e + '\n';
  });
  const sharedSentence = linedContents.split(',').join('¥n');

  useEffect(() => {
    //twitter遷移の準備
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="main"
      style={{ height: `calc(${window.innerHeight}px - 85px)` }}
    >
      <div className="img-container">
        <div
          className="item-appearance"
          style={{backgroundImage: `url(${process.env.PUBLIC_URL}/image/frame.png)`}}
          onClick={() => setIllustMode(true)}
        >
          <img
            src={`image/drinks/${
              DB[`drink${dn}`]['customs'][`custom${cn}`]['image']
            }`}
            alt={DB[`drink${dn}`]['customs'][`custom${cn}`]['name']}
          ></img>
        </div>
      </div>
      <div
        className={`green-area green-area-${
          isOrder ? 'big' : 'small'
        } text-white`}
      >
        <div className="cream-area">
          <div className="round-top" onClick={() => setIsOrder(!isOrder)}></div>
          <div className="title-container d-flex" onClick={() => setIsOrder(!isOrder)}>
            <h3 className="item-title border-left border-5">
              <span>{DB[`drink${dn}`]['name'].slice( 0, -6 )}</span><br/>{DB[`drink${dn}`]['name'].slice(-6)}
            </h3>
            <p className="drink-price">¥{priceOfDrink}</p>
          </div>
          <div className="sizes-con">
            <div className="sizes">
              <div className="op-con d-flex">
                {sizeArray.map((v, i) => (
                  <div
                    type="radio"
                    key={i.toString()}
                    id={`size-${v[0]}`}
                    className={`option option-${selectedSize === i ? 'selected' : 'not-selected'}`}
                    value={i}
                    onClick={(e) => sizeChange(e)}
                  >
                    {v}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="all-green pt-3" onClick={() => setIsOrder(!isOrder)}>
          {(() => {
            const cards = [];
            for (let i = 1; i <= lenofTopping; i++) {
              cards.push(
                <div className={`row row${i} w-100`}>
                  <div className="topping-name border-left border-2">
                    {DB[`drink${dn}`]['customs'][`custom${cn}`]['toppings'][`topping${i}`]['name']}
                  </div>
                  <span className="topping-price">
                    ¥{DB[`drink${dn}`]['customs'][`custom${cn}`]['toppings'][`topping${i}`]['price']}
                  </span>
                </div>
              );
            }
            return <div className="topping pb-3" style={{height: isOrder ? `calc(${window.innerHeight}px - 80px  - 185px - 68px - 85px)` : 'unset',}}>{cards}</div>;
          })()}
          <div className="total d-flex align-items-center mr-4 ml-4 mt-2 pt-4 pb-4 border-top">
            <TwitterShareButton
              url={'https://sutabagacha.com\n'}
              title={`${sharedSentence}`}
              hashtags={hashtags}
            >
              <TwitterIcon size="32" round />
            </TwitterShareButton>
            <h3 className="total mr-4">計 ¥{total}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

Menu.propTypes = {
  dn: PropTypes.number,
  cn: PropTypes.number,
  isOrder: PropTypes.bool,
  setIllustMode: PropTypes.func,
  setIsOrder: PropTypes.func,
};

export default Menu;