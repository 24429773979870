import React from 'react';

const NotFound = () => (
  <h1
    className="w-100"
    style={{
      height: `${window.innerHeight}px`,
      backgroundColor: '#e6dfcb',
      color: '#eb5a09',
    }}
  >
    ページが見つかりません。URLが間違っていないかご確認ください。
  </h1>
);

export default NotFound;
