import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

const HeaderBar = () => {
  return (
    <Link to="/" className="header">
      <img className="frame-l" src="image/縁左.png" alt="背景左"></img>
      <img className="frame-r" src="image/縁右.png" alt="背景右"></img>
      <img
        className="logo"
        src="image/ロゴ_改.png"
        alt="スタバガチャ ロゴ"
      ></img>
      {/* <img className="logo" src="image/icon.png" alt="サムネ用"></img> */}
    </Link>
  );
};

export default HeaderBar;
