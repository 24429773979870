import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './Top.css';
import Footer from '../../Components/Footer/Footer';
import HeaderBar from '../../Components/Header/HeaderBar';

const Top = () => {
  const [nowErrection, setNowErrection] = useState(false);
  const history = useHistory();
  const errection = () => {
    setNowErrection(true);
    setTimeout(function () {
      history.push('/result');
      setNowErrection(false);
    }, 2000);
  };

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowHeight(window.innerHeight);
    });
  });

  return (
    <div>
      <HeaderBar />
      <div
        className="background-img"
        style={{
          backgroundImage: 'url(image/背景.png)',
          height: `calc(${windowHeight}px - 85px)`,
        }}
      >
        <Link to="/tutorial">
          <div className="goto-tutorial">
            <img
              className="tutorial-button"
              src="image/使い方ボタン.png"
              alt="使い方へ"
            ></img>
            <p>使い方</p>
          </div>
        </Link>
        <Link to="/custom">
          <div className="goto-custom">
            <img
              className="custom-button"
              src="image/goToCustom.png"
              alt="おすすめカスタム一覧へ"
            ></img>
            <p>
              おすすめ
              <br />
              カスタム一覧
            </p>
          </div>
        </Link>
        <div>
          <img
            className="custom-img"
            src="image/カスタム.png"
            alt="カスタムガチャ抽選"
            onClick={errection}
          ></img>
        </div>
      </div>
      <div
        className={`anime-container anime-${nowErrection ? 'y' : 'n'} `}
        style={{ height: `calc(${window.innerHeight}px - 85px)` }}
      >
        <img
          className="h-100"
          src={`image/perform-v3${
            nowErrection ? '.gif?' + new Date().getTime() : '.png'
          }`}
          style={{ width: `calc((${window.innerHeight}px - 85px)*750/1624)` }}
          alt="ガチャ抽選演出"
        ></img>
      </div>
      <Footer />
    </div>
  );
};

export default Top;
