import React from 'react';
import HeaderBar from '../../Components/Header/HeaderBar';
import { Link } from 'react-router-dom';
import './Tutorial.css';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';

const Tutorial = () => {
  return (
    <div
      className="Tutorial"
      style={{ height: `calc(${window.innerHeight}px)` }}
    >
      <HeaderBar />
      <Link to="/">
        <img
          className="return-button"
          src="image/戻るボタン.png"
          alt="戻るボタン"
        ></img>
      </Link>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        className="slide-container"
        style={{ height: `calc(${window.innerHeight} - 80px` }}
      >
        <SwiperSlide className="slide" id="p0">
          <div className="spacing-howto"></div>
          <p>
            ガチャを回すと、スタバの
            <br />
            人気カスタムが出てくるよ！
          </p>
          <img className="" src="image/使い方1.png" alt=""></img>
        </SwiperSlide>
        <SwiperSlide className="slide" id="p1">
          <div className="spacing-howto"></div>
          <p>
            お気に入りのカスタムが
            <br />
            出てきたら、ここをタップして...
          </p>
          <img className="" src="image/使い方2.png" alt=""></img>
        </SwiperSlide>
        <SwiperSlide className="slide" id="p2">
          <div className="spacing-howto"></div>
          <p>
            この画面を店員さんに見せて
            <br />
            注文しよう！サイズ変更もできるよ！
          </p>
          <img className="" src="image/使い方3.png" alt=""></img>
        </SwiperSlide>
        <SwiperSlide className="slide" id="p3">
          <div className="spacing-howto"></div>
          <p>
            おすすめカスタム一覧から、
            <br />
            好きなフラペチーノの
            <br />
            人気カスタムが見れちゃう！
          </p>
          <img className="" src="image/使い方4.png" alt=""></img>
        </SwiperSlide>
        <SwiperSlide className="slide" id="p4">
          <p>さっそくガチャる！</p>
          <Link to="/">
            <div className="goto-top"></div>
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Tutorial;
