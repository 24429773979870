import React from 'react';
import history from './history';
import Top from './pages/Top/Top';
import Result from './pages/Result/Result';
import Custom from './pages/Custom/Custom';
import CustomDetail from './pages/Custom-detail/CustomDetail';
import manualResult from './pages/ManualResult/ManualResult';
import Tutorial from './pages/Tutorial/Tutorial';
import Form from './pages/Form/Form';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import NotFound from './pages/404';
import { Router, Switch, Route } from 'react-router-dom';

const App = () => {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path="/result" component={Result} />
          <Route exact path="/tutorial" component={Tutorial} />
          <Route exact path="/manualresult" component={manualResult} />
          <Route exact path="/detail" component={CustomDetail} />
          <Route exact path="/custom" component={Custom} />
          <Route exact path="/contact" component={Form} />
          <Route exact path="/pp" component={PrivacyPolicy} />
          <Route exact path="/" component={Top} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
