const DrinkInfo = {
  drink1: {
    name: '抹茶クリームフラペチーノ',
    image: '抹茶.png',
    price: {
      S: 525,
      T: 565,
      G: 610,
      V: 655,
    },
    customs: {
      custom1: {
        name: 'スイーツ抹茶',
        image: 'スイーツ抹茶.png',
        text: 'ホワイトモカとキャラメルがが更なる甘さの高みを追求してくれるカスタム。抹茶と相性良し。',
        param: {
          sweet: 4,
          fresh: 1,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'キャラメルソース追加',
            price: 0,
          },
          topping3: {
            name: 'ホイップ増量',
            price: 0,
          },
        },
      },
      custom2: {
        name: '本格抹茶',
        image: '本格抹茶.png',
        text: 'エスプレッソショットでさらに苦味を！本格的な抹茶が好きならこのカスタム。',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping3: {
            name: '抹茶パウダー増量',
            price: 0,
          },
        },
      },
      custom3: {
        name: 'チョコチップ抹茶',
        image: 'チョコチップ抹茶.png',
        text: 'チョコも抹茶も譲れない、そんなあなたにおすすめのカスタム。チョコチップは上に乗せてもらうのもあり！',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコレートソース追加',
            price: 0,
          },
        },
      },
      custom4: {
        name: '濃厚抹茶クリーム',
        image: '濃厚抹茶.png',
        text: '一際クリーミーなブレベミルクと抹茶のコラボ。一度試さずにはいられない！',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ブレべミルクに変更',
            price: 55,
          },
          topping2: {
            name: '抹茶パウダー増量',
            price: 0,
          },
        },
      },
      custom5: {
        name: '抹茶ホワイトチョコレート',
        image: '抹茶ホワイトチョコ.png',
        text: '抹茶とホワイトモカが最高にwellなカスタム。シンプルだけど美味しい。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
        },
      },
      custom6: {
        name: 'シングル抹茶',
        image: 'シングル抹茶.png',
        text: '甘さを抑えて抹茶を楽しみたい人のためのカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'シロップ抜き',
            price: 0,
          },
          topping2: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping3: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
        },
      },
      custom7: {
        name: '贅沢抹茶',
        image: '贅沢抹茶.png',
        text: '抹茶＋ホワイトチョコ＋チョコソースの贅沢の極みなカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping2: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom8: {
        name: '無料カスタムNo.1抹茶',
        image: '無料カスタムNo.1抹茶.png',
        text: '',
        param: {
          sweet: 0,
          fresh: 0,
        },
        toppings: {
          topping1: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping2: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping3: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
        },
      },
      custom9: {
        name: 'ほうじ茶フラペチーノ ',
        image: 'ほうじ茶風.png',
        text: 'ココアパウダーの追加でまるでほうじ茶のような味わいに！？ほうじ茶好きにはぜひ試して欲しいカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'モカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'ココアパウダー追加',
            price: 0,
          },
        },
      },
      custom10: {
        name: '超王道抹茶',
        image: '超王道抹茶.png',
        text: 'チョコと抹茶を存分に味わいたい人向けのカスタム。どちらかと言うと甘党の人向け。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加して増量',
            price: 55,
          },
          topping2: {
            name: 'ホイップ多め',
            price: 0,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom11: {
        name: '抹茶パフェ風',
        image: '抹茶パフェ風.png',
        text: '抹茶パウダーが苦味を追加してくれるカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom12: {
        name: '和カスタム',
        image: '和.png',
        text: 'ソイミルク好きにはぜひ試してみて欲しいカスタム。シナモンのアクセントがさらにwell。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ソイミルクに変更',
            price: 55,
          },
          topping2: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping3: {
            name: 'シナモンパウダー追加',
            price: 0,
          },
        },
      },
      custom13: {
        name: 'ダブル抹茶',
        image: 'ダブル抹茶.png',
        text: 'なぜこのカスタム名なのかは不明だが、シロップ抜きで甘さを抑えたカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'シロップ抜き',
            price: 0,
          },
          topping2: {
            name: '抹茶パウダー増量',
            price: 0,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping4: {
            name: 'チョコレートチップ追加',
            price: 55,
          },
        },
      },
      custom14: {
        name: 'ダブルチョコ抹茶クリーム',
        image: 'ダブルチョコ抹茶.png',
        text: 'チョコ好きにはたまらないたまらないカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping2: {
            name: 'チョコチップ追加・増量',
            price: 55,
          },
        },
      },
      custom15: {
        name: 'ジロバ抹茶',
        image: 'ジロバ抹茶.png',
        text: 'なぜジロバ抹茶なのか、カスタム名は謎に包まれている。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコレートチップ追加・増量',
            price: 55,
          },
        },
      },
      custom16: {
        name: 'コーヒ抹茶クリーム',
        image: 'コーヒー抹茶.png',
        text: '抹茶にコーヒーの風味が足された苦めのフラペチーノカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping2: {
            name: 'バニラシロップ抜き',
            price: 0,
          },
        },
      },
      custom17: {
        name: 'グリーンサファリ',
        image: 'グリーンサファリ.png',
        text: 'キャラメル好きにはぜひ試して欲しいカスタム。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'キャラメルシロップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
    },
  },
  drink2: {
    name: 'バニラクリームフラペチーノ',
    image: 'バニラフラペ.png',
    price: {
      S: 510,
      T: 550,
      G: 595,
      V: 640,
    },
    customs: {
      custom1: {
        name: 'ゴディバ風',
        image: 'ゴディバ風.png',
        text: 'かの有名チョコレート店の味になると噂のカスタム。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'モカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'チョコレートソース追加',
            price: 0,
          },
          topping3: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping4: {
            name: 'ホイップクリーム増量',
            price: 0,
          },
        },
      },
      custom2: {
        name: 'トロピカルカスタム',
        image: 'トロピカル.png',
        text: '夏の暑い季節には必ず飲みたくなるさっぱり系カスタム。',
        param: {
          sweet: 3,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'シトラス果肉追加',
            price: 110,
          },
        },
      },
      custom3: {
        name: 'オレオフラペチーノ',
        image: 'オレオ.png',
        text: 'オレオのアイスシェイクが飲めると噂のカスタム。かなり味が似ているらしい。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
        },
      },
      custom4: {
        name: 'チャイクリーム',
        image: 'チャイ.png',
        text: 'チャイが好きならぜひ試して！エキゾチックな味わいのカスタム。',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チャイシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'シナモンパウダー追加',
            price: 0,
          },
        },
      },
      custom5: {
        name: '夢カスタム',
        image: '夢.png',
        text: 'エスプレッソの苦味がきいたカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
        },
      },
      custom6: {
        name: 'ミロカスタム',
        image: 'ミロ.png',
        text: 'まるでミロのような味わいになるカスタム。ミロ知ってる人っているのかな？？',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'キャラメルシロップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping4: {
            name: 'キャラメルソース追加',
            price: 0,
          },
          topping5: {
            name: 'ココアパウダー追加',
            price: 0,
          },
        },
      },
      custom7: {
        name: 'チョコレートクリーム',
        image: 'チョコレートクリーム.png',
        text: 'チョコが好きな人にはたまらないスイーツなカスタム。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'モカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'チョコチップ追加して増量',
            price: 55,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping4: {
            name: 'ココアパウダー追加',
            price: 0,
          },
        },
      },
      custom8: {
        name: 'チョコレートクリームNo.2',
        image: 'チョコクリームNo.2.png',
        text: '全て無料でできるチョコレートクリームカスタム！',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'モカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping3: {
            name: 'ココアパウダー追加',
            price: 0,
          },
        },
      },
      custom9: {
        name: 'プレーンカスタム',
        image: 'プレーン.png',
        text: 'シロップを抜く大胆なカスタム。一度試してみて欲しい',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'バニラシロップ抜き',
            price: 0,
          },
        },
      },
      custom10: {
        name: 'ミルキーカスタム',
        image: 'ミルキー.png',
        text: 'ミルキーのような味になるカスタム。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップ追加',
            price: 55,
          },
        },
      },
      custom11: {
        name: 'ダルメシアン',
        image: 'ダルメシアン.png',
        text: 'チョコチップが中で砕けて、まるでダルメシアンのように見えることからついたカスタム名。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom12: {
        name: 'ピノ風カスタム',
        image: 'ピノ.png',
        text: 'ピノを飲み物として楽しみたい人におすすめなカスタム。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'キャラメルシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'ブレべミルクに変更',
            price: 55,
          },
          topping3: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping4: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom13: {
        name: '練乳風カスタム',
        image: '練乳風.png',
        text: '練乳好きにはおすすめのホワイトモカが激甘を演出してくれるカスタム。',
        param: {
          sweet: 5,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'ホワイトモカシロップ2ポンプ',
            price: 55,
          },
          topping3: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
      custom14: {
        name: 'キャラメル・オレオカスタム',
        image: 'キャラメルオレオ.png',
        text: 'まるでオレオのキャラメル味！？甘党にはもってこいのカスタム。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping3: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
      custom15: {
        name: '濃厚オレオカスタム',
        image: '濃厚オレオ.png',
        text: 'ただのオレオじゃなくて、濃厚なオレオ。',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'ブレべミルク追加',
            price: 55,
          },
          topping3: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
        },
      },
      custom16: {
        name: 'おすすめバニラクリーム',
        image: 'おすすめバニラ.png',
        text: '苦味を甘みがちょうどよくハーモニしているカスタム。かなり飲みやすいカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'ホワイトモカシロップ追加',
            price: 55,
          },
          topping4: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
      custom17: {
        name: 'トロピカルバニラ',
        image: 'トロピカルバニラ.png',
        text: '',
        param: {
          sweet: 0,
          fresh: 0,
        },
        toppings: {
          topping1: {
            name: 'シトラス果肉追加',
            price: 110,
          },
        },
      },
      custom18: {
        name: 'フレンチバニラカスタム',
        image: 'フレンチバニラカスタム.png',
        text: 'キャラメル好きにはおすすめのカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
    },
  },
  drink3: {
    name: 'ダークモカチップフラペチーノ',
    image: 'ダークモカ.png',
    price: {
      S: 525,
      T: 565,
      G: 610,
      V: 655,
    },
    customs: {
      custom1: {
        name: 'キャラメルチョコチップ',
        image: 'キャラメルチョコチップ.png',
        text: 'チョコとキャラメルを最大までカスタムした贅沢な味。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'キャラメルソース追加',
            price: 0,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping3: {
            name: 'チョコチップ増量',
            price: 0,
          },
          topping4: {
            name: 'ホイップクリーム増量',
            price: 0,
          },
        },
      },
      custom2: {
        name: 'シングルダークモカチップ',
        image: 'シングルダークモカチップ.png',
        text: '甘さを抑えたい人向けにエスプレッソショットを加えたカスタム。',
        param: {
          sweet: 2,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ増量',
            price: 0,
          },
        },
      },
      custom3: {
        name: 'フェレロロシェフラペチーノ',
        image: 'フェレロロシェ.png',
        text: '噂によると有名な海外のチョコのお店と同じような味になるカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'モカシロップ追加',
            price: 55,
          },
        },
      },
      custom4: {
        name: '贅沢チョコカスタム',
        image: '贅沢チョコ.png',
        text: 'シトラスの柑橘とブレベの濃厚なミルクの相性が気になるカスタム。チャレンジしてみるのもアリかもしれません...',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ブレべミルクに変更',
            price: 55,
          },
          topping2: {
            name: 'シトラス果肉追加',
            price: 110,
          },
          topping3: {
            name: 'モカシロップ追加',
            price: 55,
          },
          topping4: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom5: {
        name: '超濃厚チョコレート',
        image: '超濃厚チョコレート.png',
        text: 'シンプルにクリーミーにしたいにおすすめのカスタム。',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ブレべミルクに変更',
            price: 55,
          },
          topping2: {
            name: 'ホイップ増量',
            price: 0,
          },
        },
      },
      custom6: {
        name: 'チョコ尽くし',
        image: 'チョコづくし.png',
        text: 'チョコ好きにチョコ好きのためのカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping2: {
            name: 'チョコチップ増量',
            price: 0,
          },
          topping3: {
            name: 'ココアパウダー追加',
            price: 0,
          },
        },
      },
      custom7: {
        name: 'Best of チョコカスタム',
        image: 'ベストオブチョコ.png',
        text: 'チョコも楽しみたいし、コーヒーのコクも欲しい人向けのカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom8: {
        name: '濃厚ビターチョコ',
        image: '濃厚ビターチョコ.png',
        text: 'チョコも楽しみたいし、コーヒーのコクも欲しい人向けのカスタム。',
        param: {
          sweet: 2,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'エスプレッソショット追加',
            price: 55,
          },
          topping2: {
            name: 'ホイップクリーム抜き',
            price: 0,
          },
          topping3: {
            name: 'チョコパウダー増量',
            price: 0,
          },
        },
      },
      custom9: {
        name: '高級チョコレート',
        image: '高級チョコ.png',
        text: 'あえてチョコチップを抜いたカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'モカシロップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコチップ抜き',
            price: 0,
          },
        },
      },
      custom10: {
        name: '超濃厚チョコカスタム2',
        image: '超濃厚チョコ2.png',
        text: '甘くて濃厚なチョコのカスタム。',
        param: {
          sweet: 3,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'ブレべミルクに変更',
            price: 55,
          },
          topping2: {
            name: 'チョコソース追加・増量',
            price: 0,
          },
        },
      },
    },
  },
  // drink4: {
  //   name: 'コールドブリューコーヒーフラペチーノ',
  //   image: 'コールドブリュー_ホイップなし.png',
  //   price: {
  //     S: 510,
  //     T: 550,
  //     G: 595,
  //     V: 640,
  //   },
  //   customs: {
  //     custom1: {
  //       name: 'ティラミスカスタム',
  //       image: 'ティラミス.png',
  //       text: 'シロップを二つも追加した仏では少し考えられないカスタム。まるでティラミスのような味になる。',
  //       param: {
  //         sweet: 4,
  //         fresh: 1,
  //       },
  //       toppings: {
  //         topping1: {
  //           name: 'バニラシロップ追加',
  //           price: 55,
  //         },
  //         topping2: {
  //           name: 'モカシロップ追加',
  //           price: 55,
  //         },
  //         topping3: {
  //           name: 'エスプレッソショット追加',
  //           price: 55,
  //         },
  //         topping4: {
  //           name: 'ホイップクリーム追加',
  //           price: 55,
  //         },
  //         topping5: {
  //           name: 'チョコソース追加',
  //           price: 0,
  //         },
  //         topping6: {
  //           name: 'キャラメルソース追加',
  //           price: 0,
  //         },
  //       },
  //     },
  //     custom2: {
  //       name: 'ビターコーヒーカスタム',
  //       image: 'ビターコーヒーカスタム.png',
  //       text: 'シンプルにコーヒーの苦味を他したい人向けのカスタム。',
  //       param: {
  //         sweet: 2,
  //         fresh: 3,
  //       },
  //       toppings: {
  //         topping1: {
  //           name: 'エスプレッソショット追加',
  //           price: 55,
  //         },
  //       },
  //     },
  //     custom3: {
  //       name: 'ちゃんみおスペシャル',
  //       image: 'ちゃんみおスペシャル.png',
  //       text: '「日常」に出てくるキャラクターが注文していたカスタム。アニメファンなら一度は飲んでおきたい。',
  //       param: {
  //         sweet: 4,
  //         fresh: 2,
  //       },
  //       toppings: {
  //         topping1: {
  //           name: 'ホワイトモカシロップ追加',
  //           price: 0,
  //         },
  //         topping2: {
  //           name: 'チョコチップ追加',
  //           price: 55,
  //         },
  //         topping3: {
  //           name: 'ホイップクリーム追加',
  //           price: 55,
  //         },
  //         topping4: {
  //           name: 'エスプレッソショット追加',
  //           price: 55,
  //         },
  //         topping5: {
  //           name: 'キャラメルソース追加',
  //           price: 0,
  //         },
  //       },
  //     },
  //   },
  // },
  drink4: {
    name: 'ダークモカチップクリームフラペチーノ',
    image: 'ダークモカクリーム.png',
    price: {
      S: 525,
      T: 565,
      G: 610,
      V: 655,
    },
    customs: {
      custom1: {
        name: 'オレンジチョコカスタム',
        image: 'オレンジチョコ.png',
        text: 'オレンジとチョコの組み合わせが美味しくないわけがないカスタム。',
        param: {
          sweet: 3,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'シトラス果肉追加',
            price: 110,
          },
        },
      },
      custom2: {
        name: 'もりもりチョコカスタム',
        image: 'もりもりチョコ.png',
        text: 'チョコ好きにはぜひ試して欲しいカスタム。',
        param: {
          sweet: 4,
          fresh: 2,
        },
        toppings: {
          topping1: {
            name: 'チョコソース追加・増量',
            price: 0,
          },
          topping2: {
            name: 'ダークパウダー追加',
            price: 0,
          },
          topping3: {
            name: 'チョコチップ増量',
            price: 0,
          },
          topping4: {
            name: 'モカシロップ追加',
            price: 55,
          },
        },
      },
      custom3: {
        name: 'Wチョコカスタム',
        image: 'double-choco.png',
        text: 'ホワイトチョコも一緒に味わいたい人におすすめのカスタム。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップ追加',
            price: 55,
          },
        },
      },
      custom4: {
        name: 'アーモンドチョコカスタム',
        image: 'アーモンドチョコ.png',
        text: 'アーモンドミルクに変更は上級者の証。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'アーモンドミルクに変更',
            price: 55,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping3: {
            name: 'チョコチップ追加',
            price: 55,
          },
        },
      },
      custom5: {
        name: 'オレンジチョコカスタム改',
        image: 'オレンジチョコ改.png',
        text: 'オレンジバニラシュガーを使った激レアなカスタム。非常にさっぱりとした味わいに...',
        param: {
          sweet: 3,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'シトラス果肉追加',
            price: 110,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping3: {
            name: 'オレンジバニラシュガー追加',
            price: 0,
          },
        },
      },
      custom6: {
        name: 'ホワイトチョコレートモカカスタム',
        image: 'ホワイトチョコレートモカ.png',
        text: 'キャラメルは苦手だけど、ホワイトチョコは好きな人におすすめのカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'ホワイトモカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'キャラメルソース抜き',
            price: 0,
          },
        },
      },
    },
  },
  drink5: {
    name: 'キャラメルフラペチーノ',
    image: 'キャラメルフラペ.png',
    price: {
      S: 510,
      T: 550,
      G: 595,
      V: 640,
    },
    customs: {
      custom1: {
        name: 'チョコチップキャラメルカスタム',
        image: 'チョコチップキャラメル.png',
        text: 'キャラメルとチョコのハーモニーが嬉しいカスタム。',
        param: {
          sweet: 3,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
      custom2: {
        name: 'キャラメルクッキーバーカスタム',
        image: 'キャラメルクッキーバー.png',
        text: 'アメリカのフラペチーノが楽しめるカスタム。味は酷似しているらしい。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'チョコソース追加',
            price: 0,
          },
          topping3: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
      custom3: {
        name: 'ジバチップフラペチーノ',
        image: 'ジバチップ.png',
        text: 'ジバチップとはなんなのだろうか、名前の由来はよくわからないカスタム。',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'モカシロップに変更',
            price: 0,
          },
          topping2: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping3: {
            name: 'チョコソース追加',
            price: 0,
          },
        },
      },
    },
  },
  drink6: {
    name: 'マンゴーパッションフラペチーノ',
    image: 'マンゴーパッション.png',
    price: {
      S: 510,
      T: 550,
      G: 595,
      V: 640,
    },
    customs: {
      custom1: {
        name: '白桃フラペチーノ',
        image: '白桃.png',
        text: 'マンゴーなのに白桃の味になる。個人的に一番大好きなカスタム。',
        param: {
          sweet: 4,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'パッションティー抜き',
            price: 0,
          },
          topping2: {
            name: 'ホワイトモカシロップ追加',
            price: 55,
          },
          topping3: {
            name: 'ホイップクリーム追加',
            price: 55,
          },
        },
      },
      custom2: {
        name: 'シトラス・マンゴーカスタム',
        image: 'シトラス＆マンゴー.png',
        text: '爽やかさにおいてはダントツの実力を持つカスタム。',
        param: {
          sweet: 3,
          fresh: 5,
        },
        toppings: {
          topping1: {
            name: 'シトラス果肉追加',
            price: 110,
          },
          topping2: {
            name: 'ホイップクリーム追加',
            price: 55,
          },
          topping3: {
            name: 'パッションティー抜き',
            price: 0,
          },
        },
      },
      custom3: {
        name: 'パイナップルカスタム',
        image: 'パイナップル.png',
        text: 'チャイの不思議な味がマンゴーをパイナップルに進化させるカスタム。',
        param: {
          sweet: 3,
          fresh: 5,
        },
        toppings: {
          topping1: {
            name: 'パッションティー抜き',
            price: 0,
          },
          topping2: {
            name: 'チャイシロップ追加',
            price: 55,
          },
          topping3: {
            name: 'シトラス果肉追加',
            price: 110,
          },
          topping4: {
            name: 'ホイップクリーム追加',
            price: 55,
          },
        },
      },
      custom4: {
        name: '完熟マンゴーカスタム',
        image: '完熟マンゴー.png',
        text: 'マンゴーはマンゴでも、完熟マンゴー！さっぱり好きにはおすすめ！',
        param: {
          sweet: 3,
          fresh: 5,
        },
        toppings: {
          topping1: {
            name: 'パッションティー抜き',
            price: 0,
          },
        },
      },
      custom5: {
        name: 'さっぱりフルーツカスタム',
        image: 'さっぱりフルーツ.png',
        text: 'ホイップクリームで少しマイルドさを追加したカスタム！',
        param: {
          sweet: 3,
          fresh: 4,
        },
        toppings: {
          topping1: {
            name: 'パッションティー抜き',
            price: 0,
          },
          topping2: {
            name: 'ホイップクリーム追加',
            price: 55,
          },
        },
      },
    },
  },
  drink7: {
    name: 'エスプレッソアフォガードフラペチーノ',
    image: 'アフォガ_ホイップなし.png',
    price: {
      S: 590,
      T: 630,
      G: 675,
      V: 720,
    },
    customs: {
      custom1: {
        name: 'アフォガードNo.1カスタム',
        image: 'アフォガードNo.1.png',
        text: 'シンプルにチョコとキャラメルを追加したカスタム！',
        param: {
          sweet: 4,
          fresh: 3,
        },
        toppings: {
          topping1: {
            name: 'チョコチップ追加',
            price: 55,
          },
          topping2: {
            name: 'キャラメルソース追加',
            price: 0,
          },
        },
      },
    },
  },
};
export default DrinkInfo;
