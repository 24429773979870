import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './result.scss';
import Footer from '../../Components/Footer/Footer';
import Menu from './Menu/Menu';
import LookItem from '../LookItem';
import DB from '../../Components/DB';
import HeaderBar from '../../Components/Header/HeaderBar';

const Result = () => {
  const [illustMode, setIllustMode] = useState(true);
  const [returnToTop, setReturnToTop] = useState('true');
  const [isOrder, setIsOrder] = useState(true);
  const [dn, setdn] = useState(Math.ceil(Math.random() * 8)); //drink number
  const [lenofCustom, setLenofCustom] = useState(Object.keys(DB[`drink${dn}`]['customs']).length);
  const [cn, setcn] = useState(Math.ceil(Math.random() * lenofCustom)); //custom number

  useEffect(() => {
    setTimeout(function () {
      setIllustMode(false)
      setReturnToTop(false)
    }, 3000);
    setTimeout(function () {
      setIsOrder(false);
    }, 3500);
  }, []);

  return (
    <div>
      <HeaderBar />
      <div
        className="background-img"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/image/背景${illustMode ? 'ぼかし' : ''}.png)`,
          height: `calc(${window.innerHeight}px - 85px)`,
        }}
      >
        <Link to="/">
          <img
            className={`return-button ${(returnToTop | (!returnToTop&&!illustMode&&!isOrder)) ? 'd-block' : 'd-none'}`}
            src="image/戻るボタン.png"
            alt="戻るボタン"
          ></img>
        </Link>
        <div
          className={`illust-mode w-100 h-100 ${illustMode ? 'd-block' : 'd-none'}`}
        >
          <LookItem dn={dn} cn={cn} setIllustMode={setIllustMode}/>
        </div>
        <div
          className={`not-illust-mode h-100 ${
            illustMode ? 'd-none' : 'd-block'
          }`}
        >
          <Menu dn={dn} cn={cn} isOrder={isOrder} setIllustMode={setIllustMode} setIsOrder={setIsOrder}/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Result;