import React from 'react';
import '../Result/result.css';
import Footer from '../../Components/Footer/Footer';
import ManualMenu from './ManualMenu';
import HeaderBar from '../../Components/Header/HeaderBar';
import PropTypes from 'prop-types';

class ManualResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //CustomDetailから渡ってくる
      dn: this.props.location.state.dn,
      numberOfCustoms: this.props.location.state.numberOfCustoms,
      cn: this.props.location.state.cn,
      scrollTop: this.props.location.state.scrollTop,
    };
  }
  goBack(e) {
    this.setState({}, () => {
      this.props.history.push({
        pathname: '/detail',
        state: {
          dn: this.state.dn,
          numberOfCustoms: this.state.numberOfCustoms,
          cn: this.state.cn,
          scrollTop: this.state.scrollTop,
        },
      });
    });
  }

  render() {
    return (
      <div>
        <HeaderBar />
        <div
          className="background-img"
          style={{backgroundImage: 'url(image/背景.png)'}}
        >
          <div onClick={(e) => this.goBack(e)}>
            <img
              className="return-button"
              src="image/戻るボタン.png"
              alt="戻るボタン"
            ></img>
          </div>
          <ManualMenu manualDn={this.state.dn} manualCn={this.state.cn} goBack={this.goBack} />
        </div>
        <Footer />
      </div>
    );
  }
}

ManualResult.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
};

export default ManualResult;
