const Tips = {
  tips1: {
    title: '1万円札でも大丈夫',
    detail: 'レジのお釣りは自動処理なので大丈夫です。',
  },
  tips2: {
    title: 'タンブラーを持ってくると',
    detail: 'タンブラー持参で、容器の分だけ値引きされます！',
  },
  tips3: {
    title: 'コーヒーおかわり！',
    detail: 'ドリップコーヒーは二杯目割引されます。',
  },
  tips4: {
    title: 'スタバカードでの支払いでお得',
    detail: 'ドリンク一杯無料のなどの特典がつくことも..',
  },
  tips5: {
    title: 'タンブラーを買うと...',
    detail: 'スタバでタンブラーを買うと、ドリンク券がついてくるよ',
  },
  tips6: {
    title: '十人十色のカスタマイズ',
    detail: '無料から有料まで幅広いカスタマイズでドリンクを楽しめる',
  },
  tips7: {
    title: 'コミューターマグクーポン',
    detail: 'タンブラー等の対象商品を買うと,ドリンク無料券がもらえる',
  },
  tips8: {
    title: '注意',
    detail: 'カスタムには「増量」と「追加」の二種類ある',
  },
  tips9: {
    title: 'ブレべミルクとは...',
    detail: 'イタリアの言葉で「凝縮」の意味を持つ。非常に濃いミルク',
  },
  tips10: {
    title: '大人のキミには...',
    detail: 'エスプレッソショット（濃いコーヒー）を追加して見ては？',
  },
  tips11: {
    title: '夜でもコーヒーを',
    detail: '「ディカフェで」といえば50円で99%カフェイン抜きにしてくれる',
  },
  tips12: {
    title: '熱いのが苦手なら...',
    detail: 'ぬるめにしてくださいと言えば、飲みやすい温度に調節してくれる',
  },
  tips13: {
    title: 'スタバ不思議話',
    detail: 'スタバには金属製のスプーンが10前後しかないらしい',
  },
  tips14: {
    title: '飲み切れるのなら',
    detail: '実はグランデサイズが一番お得、＋８０円でショートの二倍の量',
  },
  tips15: {
    title: 'スタバには◯◯がない',
    detail: '時間が経つのを忘れさせるため、時計がない(一部店舗除く)',
  },
  tips16: {
    title: 'スタバは禁煙(一部店舗除く)',
    detail: 'コーヒーの香りを最大限感じられる',
  },
  tips17: {
    title: 'スタバの名前の由来...',
    detail: '「星」が由来ではなく、小説に出てくるある人物だとか',
  },
  tips18: {
    title: '超激レアエプロン？',
    detail: '茶色のエプロンをした店員さんは超激レア',
  },
  tips19: {
    title: 'アフォガードとは...',
    detail: '「溺れた（アイスクリーム）」という意味を持つ',
  },
  tips20: {
    title: 'まずは席をとってから',
    detail: '店内利用ならまず席を確保しよう！',
  },
  tips21: {
    title: 'サイズを選んでから...',
    detail: 'オーダー画面を見せるだけで注文ができるよ',
  },
  tips22: {
    title: 'スクショチャンス',
    detail: 'お気に入りのカスタムが引けたらシェアしよう！',
  },
  tips23: {
    title: '漢なら...',
    detail: '最初に引いたやつにしなよ',
  },
  tips24: {
    title: 'わたしたち...',
    detail: 'teamSODAはロゴやウェブUIデザイン等をやっております！',
  },
  tips25: {
    title: 'ゲームにも!?',
    detail: '最初に抹茶フラペのカスタムを引いたら勝ち!?',
  },
  tips26: {
    title: '君もカスタム博士',
    detail: '全種類飲んだらね',
  },
  tips27: {
    title: 'ゲームにも!?',
    detail: '最初に抹茶フラペのカスタムを引いたら勝ち!?',
  },
  tips28: {
    title: '気分転換に',
    detail: 'いつもとは違うフラペもいいんじゃない？',
  },
};
export default Tips;
